import styled from 'styled-components'
import 'react-tooltip/dist/react-tooltip.css'
import { Text, useToast, LinkExternal, useMatchBreakpoints } from '@pancakeswap/uikit'
import React, { useState } from 'react'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import poolAbi from 'config/abi/pools.json'
import { useDispatch } from 'react-redux'
import { setRefLink } from 'state/referral'
import { useSigner } from 'wagmi'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import { formatEther } from '@ethersproject/units'
import { shortenURL } from './util'
import { getBlockExploreLink } from 'utils'
import PageReferral from './components/PageReferral'
import PageProfile from './components/PageProfile'
import PageDashboardV1 from './components/ReferralDashboardV1'
import TopRank from './components/TopRank'
import { SEOPage } from 'components/SEOPage'
import { PageMeta } from 'components/Layout/Page'

const Wrapper = styled.div`
  * {
    font-family: Inter, sans-serif;
  }
  width: 100%;
  max-width: 1224px;
  height: auto;
  min-height: 500px;
  margin: 0 auto;
  padding: 139px 0 20px;
  display: flex;
  gap: 24px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 100px 0;
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    padding: 100px 0 20px;
    width: 100%;
  }
`

const Menu = styled.div`
  max-width: 250px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
  }
  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    justify-content: space-around;
  }
`
const MenuItemActive = styled.div`
  width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  img {
    width: 24px;
    height: 24px;
  }
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #1a1a1a;
  }
  @media screen and (max-width: 900px) {
    width: 40%;
  }
  @media screen and (max-width: 575px) {
    padding: 0 8px;
    width: 100%;
    flex-direction: column;
  }
`
const MenuItemDisabled = styled.div`
  width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;

  img {
    width: 24px;
    height: 24px;
  }
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: rgba(103, 102, 110, 1);
  }
  cursor: pointer;
  @media screen and (max-width: 575px) {
    padding: 0 8px;
    width: 100%;
    flex-direction: column;
  }
`
const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const copyText = (text) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

const arr = [
  { id: 1, name: 'Profile' },
  { id: 2, name: 'Referrals' },
  { id: 3, name: 'Referral Dashboard' },
  // { id: 4, name: 'Top Ranking' },
]

const Referral = () => {
  const [linkRef, setLinkRef] = React.useState('')
  const [showCopied, setShowCopied] = React.useState(false)
  const { account } = useWeb3React()
  // account = '0x11205d2c06C2F392C1C20aCFa2b2f2de1882608e'
  const dispatch = useDispatch()
  const { toastSuccess, toastError } = useToast()
  const [loading, setLoading] = React.useState(false)

  const { search } = window.location
  const query = new URLSearchParams(search)
  const { isMobile } = useMatchBreakpoints()
  const referBy = query.get('ref')
  const baseRefUrl = `${window.location.origin}homepage?ref=`
  const { data: signer } = useSigner()

  const meta = SEOPage
  const findMeta = meta.find((item) => item.key === 'referral')

  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const poolCT = getContract({
    address: addresses.pools[CHAIN_ID],
    abi: poolAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [userIsRegister, setUserIsRegister] = useState(false)

  const [myCode, setMyCode] = useState('')
  const [referByWallet, setReferByWallet] = useState(referBy)
  const [referCode, setReferCode] = useState('')
  const [showError, setShowError] = useState(true)
  const [userSearch, setUserSearch] = useState('')
  const [resultSearch, setResultSearch] = useState('')

  const [tab, setTab] = useState(1)
  const [acountChild, setAccountChild] = useState(() => {
    if (account) {
      return [account]
    }
    return []
  })
  // const [OP2USDT, setOP2USDT] = useState(0)

  const [remainKol, setRemainKol] = useState(0)
  const [rewardsKol, setRewardsKol] = useState(0)
  const [totalLock, setTotalLock] = useState(0)
  // const [from, setFrom] = useState({
  //   from: account,
  //   floor: 0,
  // })
  const [userInfos, setUserInfo] = useState({
    refferBy: '',
    date: 0,
    totalReffer: '',
    referDownline: '',
    directStaked: 0,
    // totalStaked5: 0,
    totalLockUSD: 0,
    maxOutUSD: 0,
    totalComms: 0,
    totalClaimUSD: 0,
    totalAvailable: 0,
    rewardsKolUSD: 0,
    OP2USDT: 0,
    totalComms2: 0,
  })

  const validateReferByWallet = async (e) => {
    setReferCode(e.target.value)
    const code = e.target.value

    const userInfosByCode = await refferCT.userInfosByCode(code.toLowerCase())
    if (userInfosByCode.user === '0x0000000000000000000000000000000000000000') setShowError(true)
    else {
      setShowError(false)
      setReferByWallet(userInfosByCode.user)
    }
  }

  // const handleChangeChild = (accountB) => {
  //   setFrom({
  //     from: accountB,
  //     floor: from.floor + 1,
  //   })
  //   // setListChild(
  //   //   listChild.map((l) => {
  //   //     if (l.account.toLowerCase() === accountB.toLowerCase()) l.showChild = !l.showChild
  //   //     return l
  //   //   }),
  //   // )
  // }

  const getUserInfo = async () => {
    if (!account) return
    const infos = await Promise.all([
      refferCT.userInfos(account),
      poolCT.getChildren(account),
      poolCT.remainCommUSD(account),
      poolCT.userTotalLockUSD(account),
      poolCT.usermaxOutUSD(account),
      poolCT.totalRewardsUSD(account),
      poolCT.OP2USDT(),
      poolCT.totalComms(account),
      poolCT.totalRewardsActiveKolUSD(account),
      poolCT.totalComms2(account),
      poolCT.remainComm2(account),
      poolCT.remainRewardsActiveKolUSD(account),
    ])
    // console.log(infos)
    const totalCurentRewards = await Promise.all([
      poolCT.currentReward(0, account),
      poolCT.currentReward(1, account),
      poolCT.currentReward(2, account),
      poolCT.currentReward(3, account),
    ])
    let totalCurentReward = 0
    totalCurentRewards.map((t) => {
      totalCurentReward += Number(formatEther(t))
      return t
    })
    const user = {
      refferBy: infos[0].refferBy.toString(),
      date: Number(infos[0].dateTime.toString()) * 1000,
      totalReffer: infos[0].totalRefer.toString(),
      referDownline: infos[0].totalRefer5.toString(),
      directStaked: Number(infos[1].direct.toString()),
      totalLockUSD: Number(formatEther(infos[3])),
      maxOutUSD: Number(formatEther(infos[4])),
      totalComms: Number(formatEther(infos[7])),
      totalClaimUSD: Number(formatEther(infos[5])),
      totalAvailable:
        Number(formatEther(infos[2])) +
        Number(formatEther(infos[10])) +
        Number(formatEther(infos[11])) +
        totalCurentReward,
      rewardsKolUSD: Number(formatEther(infos[8])),
      OP2USDT: Number(formatEther(infos[6])),
      totalComms2: Number(formatEther(infos[9])),
    }

    setUserInfo(user)
  }

  const getRemainKol = async () => {
    const remainKol = await poolCT.childs(account)
    const rewardsKol = await poolCT.totalRewardsActiveKolUSD(account)
    const totalLock = await poolCT.userTotalLockUSD(account)
    setTotalLock(totalLock.toString())
    setRewardsKol(Number(formatEther(rewardsKol.toString())))
    setRemainKol(remainKol.remainKol.toString())
  }

  const getData = async () => {
    if (!account) return
    const checkUserRegister = async () => {
      const isRegister = await refferCT.isReferrer(account)
      setUserIsRegister(isRegister)
      if (isRegister) {
        setLinkRef(getLinkRef())
      } else {
        setLinkRef('')
      }
    }
    await checkUserRegister()
    await getRemainKol()
    // getRefer()
  }
  // const handleBack = () => {
  //   const newArr = [...acountChild]
  //   newArr.pop()
  //   setAccountChild(newArr)
  //   // getTotalRefferChild(0, newArr[newArr.length - 1])
  // }

  React.useEffect(() => {
    getData()
    // setFrom({
    //   from: account,
    //   floor: 0,
    // })
  }, [account])

  React.useEffect(() => {
    if (!account) {
      return
    }

    setMyCode(account.slice(account.length - 8, account.length).toLocaleLowerCase())
    getRefferCode()
    getUserInfo()
    if (!acountChild.length && account) {
      setAccountChild([...acountChild, account])
    } else {
      setAccountChild([account])
    }
  }, [account])

  const getLinkRef = () => {
    const param = window.location.origin
    const text = `${param}?ref=${account.slice(account.length - 8, account.length).toLocaleLowerCase()}`
    return text
  }

  const getRefferCode = async () => {
    if (referBy) {
      const userReferBy = await refferCT.userInfosByCode(referBy.toLowerCase())
      if (userReferBy.user === '0x0000000000000000000000000000000000000000') {
        setShowError(true)
      } else {
        setReferCode(referBy.slice(referBy?.length - 8, referBy?.length).toLocaleLowerCase())
      }
    } else {
      const ref = localStorage.getItem('saveAdd')
      if (JSON.parse(ref)) {
        const userReferByLocal = await refferCT.userInfosByCode(JSON.parse(ref).toLowerCase())
        if (userReferByLocal.user === '0x0000000000000000000000000000000000000000') {
          setShowError(true)
        } else {
          setReferCode(JSON.parse(ref).toLocaleLowerCase())
          setShowError(false)
        }
      }
    }
  }
  const onRegister = async () => {
    try {
      let referByW = referByWallet
      if (!referByW) {
        if (referCode) {
          const userInfosByCode = await refferCT.userInfosByCode(referCode.toLowerCase())
          referByW = userInfosByCode.user
        }
      }
      const txReceipt = await refferCT.register(referByW, myCode)
      if (txReceipt?.hash) {
        dispatch(setRefLink(`${baseRefUrl}${account}`))
        toastSuccess('Congratulations, you have successfully registered!')
        setLinkRef(getLinkRef())
        getData()
      } else {
        toastError('Please try again. Confirm the transaction and make sure you are paying enough gas!')
      }
      setLoading(false)
    } catch (error) {
      console.log('onRegister error:', error)
      setLoading(false)
      toastError('Please try again. Confirm the transaction and make sure you are paying enough gas!')
    }
  }

  const handleRef = () => {
    if (userIsRegister) {
      const text = getLinkRef()
      copyText(text)
      setShowCopied(true)
    }
  }

  const handleLeave = () => {
    setTimeout(() => {
      setShowCopied(false)
    }, 100)
  }
  const handleSearchUserCode = async () => {
    const userInfosByCode = await refferCT.userInfosByCode(userSearch.toLowerCase())
    if (userInfosByCode.user === '0x0000000000000000000000000000000000000000') {
      setResultSearch('Invalid code')
      return
    }
    setResultSearch(userInfosByCode.user)
  }

  const showResultSearch = () => {
    if (resultSearch === '') return null
    if (resultSearch === 'Invalid code')
      return <p style={{ color: 'red', marginTop: 20, textAlign: 'center', fontWeight: '600' }}>Invalid code</p>
    return (
      <Text style={{ color: '#0784ce', marginTop: 30 }} ellipsis={true}>
        <LinkExternal
          fontSize={['14px', '16px', '18px', '18px', '18px']}
          href={getBlockExploreLink(resultSearch, 'address', CHAIN_ID)}
          ellipsis={true}
          style={{ color: '#0784ce' }}
          color="#0784ce"
        >
          {shortenURL(`${resultSearch}`, 16)}
        </LinkExternal>
      </Text>
    )
  }

  return (
    <>
      <PageMeta meta={findMeta} />
      <Wrapper>
        {isMobile ? (
          <MenuMobile>
            <div style={{ display: 'flex' }}>
              {arr.map((i, ix) => (
                <React.Fragment key={ix}>
                  {tab === ix ? (
                    <MenuItemActive>
                      <Text className="title">{i.name}</Text>
                    </MenuItemActive>
                  ) : (
                    <MenuItemDisabled onClick={() => setTab(ix)}>
                      <Text className="title">{i.name}</Text>
                    </MenuItemDisabled>
                  )}
                </React.Fragment>
              ))}
            </div>
          </MenuMobile>
        ) : (
          <Menu>
            {arr.map((i, ix) => (
              <React.Fragment key={ix}>
                {tab === ix ? (
                  <MenuItemActive>
                    <Text className="title">{i.name}</Text>
                  </MenuItemActive>
                ) : (
                  <MenuItemDisabled onClick={() => setTab(ix)}>
                    <Text className="title">{i.name}</Text>
                  </MenuItemDisabled>
                )}
              </React.Fragment>
            ))}
          </Menu>
        )}
        {tab === 0 && <PageProfile userInfos={userInfos} />}
        {tab === 1 && (
          <PageReferral
            linkRef={linkRef}
            totalLock={totalLock}
            remainKol={remainKol}
            getRemainKol={() => getRemainKol()}
            handleRef={handleRef}
            handleLeave={handleLeave}
            userIsRegister={userIsRegister}
            showCopied={showCopied}
            referCode={referCode}
            validateReferByWallet={validateReferByWallet}
            showError={showError}
            onRegister={onRegister}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            handleSearchUserCode={handleSearchUserCode}
            showResultSearch={showResultSearch}
            setTab={setTab}
          />
        )}
        {tab === 2 && (
          <PageDashboardV1 account={account} acountChild={acountChild} refferCT={refferCT} poolCT={poolCT} />
        )}
        {/* {tab === 3 && <TopRank refferCT={refferCT} poolCT={poolCT} />} */}
      </Wrapper>
    </>
  )
}

export default Referral
