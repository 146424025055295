import { Heading, Text } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'
import truncateHash from '@pancakeswap/utils/truncateHash'
import CountUp from 'react-countup'
import moment from 'moment'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import images from 'configs/images'

const StyledHead = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -0.96px;
  background: var(--primary-primary-gradient-2, linear-gradient(180deg, #ff0a23 0%, #f77b88 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const CardInfoUser = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 900px) {
    padding 0px 16px;
    width: 100%;
  }
`
const BlockInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const Label = styled(Text)`
  width: 100%;
  color: #202327;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
const Value = styled.div`
  color: #202327;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const Info = styled.div`
  width: 60%;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(#f1f4f9, #f1f4f9), linear-gradient(180deg, #fef0f0 27%, #fca6a6 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  gap: 22px;
  @media (max-width: 1024px) {
    width: 100%;
    padding 16px;
    gap: 16px;
  }
`
const InfoItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`
const YourCommission = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid transparent;
  background-image: linear-gradient(#f1f4f9, #f1f4f9), linear-gradient(180deg, #fef0f0 27%, #fca6a6 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 20px 20px;
  }
`
const StyledItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  @media (max-width: 575px) {
    flex-direction: column;
    gap: 4px;
  }
`
const StyledTilde = styled.div`
  color: #202327;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
const Item = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #d2d2d2;
  @media (max-width: 575px) {
    padding: 14px;
  }
`
const StyledInfoItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`
const StyledSubtitle = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 18px;
  color: rgba(173, 171, 178, 1);
  @media screen and (max-width: 575px) {
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
  }
  .title {
    color: #000;
    font-weight: 700;
  }
`

const PageProfile = ({ userInfos }) => {
  const reward = userInfos.rewardsKolUSD / 2 +
  userInfos.totalClaimUSD +
  userInfos.totalComms +
  userInfos.totalComms2 +
    userInfos.totalAvailable
  const MyCircularProgressBar = ({ totalAvailable }) => {
    const maxOut = userInfos.maxOutUSD || 100

    const percentage = (reward / maxOut) * 100

    return (
      <div style={{ width: '300px' }}>
        <CircularProgressbar
          value={percentage}
          text={`${percentage.toFixed(2)}%`}
          strokeWidth={5}
          styles={buildStyles({
            pathColor: `rgba(239, 67, 67, ${percentage / 10})`,
            textColor: '#202327',
            trailColor: 'rgba(32, 35, 39, 0.20)',
            textSize: '20px',
          })}
        />
        <div style={{ fontSize: '16px', color: '#202327', marginTop: '16px', textAlign: 'center' }}>
          Your Total Earn / MaxOut
        </div>
      </div>
    )
  }

  return (
    <CardInfoUser>
      <StyledHead>User Info</StyledHead>
      <StyledSubtitle>*Note</StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Total referrals:</span> Direct <a style={{ color: 'blue' }}>(F1)</a> total registered
        using referral link <b />
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Refer Downline:</span> Total Number of Members Registered in the User&lsquo;s System
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Direct staked:</span> Total Amount of <a style={{ color: 'blue' }}>(F1)</a> Deposited
        (Investment){' '}
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Total Locks</span> Total Dollars User Deposited
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Max Out</span> Maximum User Can Claim
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Total Direct Commission</span> Total Direct Commission Claimed
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Total Interest Commission</span> Total Interest Commission Claimed
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Total Claim Reward</span> Total Profit Claimed
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Total Available</span> Total Balance Available
      </StyledSubtitle>
      <StyledSubtitle>
        <span className="title">Total Earned</span> Bonus amount based on total (Includes Daily Profit + Direct
        Commission + 5-Level Staking Commission + KOLs Commission)
      </StyledSubtitle>
      <BlockInfo>
        <Info style={{ width: '100%' }}>
          <StyledInfoItem>
            <InfoItem>
              <Label>Total referrals</Label>
              <StyledItem>
                <Item>
                  <Value>{userInfos.totalReffer}</Value>
                </Item>
              </StyledItem>
            </InfoItem>
            <InfoItem>
              <Label>Refer Downline</Label>
              <StyledItem>
                <Item>
                  <Value>{userInfos.referDownline}</Value>
                </Item>
              </StyledItem>
            </InfoItem>
          </StyledInfoItem>
          <StyledInfoItem>
            <InfoItem>
              <Label>Direct staked</Label>
              <StyledItem>
                <Item>
                  <Value>{userInfos.directStaked}</Value>
                </Item>
              </StyledItem>
            </InfoItem>
          </StyledInfoItem>
        </Info>
      </BlockInfo>
      <BlockInfo>
        <Info>
          <InfoItem>
            <Label>Total Locks</Label>
            <StyledItem>
              <Item>
                <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                <Value>
                  <CountUp
                    separator=","
                    start={0}
                    preserveValue
                    delay={0}
                    end={userInfos.totalLockUSD}
                    decimals={2}
                    duration={1}
                  />
                </Value>
              </Item>
            </StyledItem>
          </InfoItem>
          <InfoItem>
            <Label>Max Out</Label>
            <StyledItem>
              <Item>
                <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                <Value>
                  <CountUp
                    separator=","
                    start={0}
                    preserveValue
                    delay={0}
                    end={userInfos.maxOutUSD}
                    decimals={2}
                    duration={1}
                  />
                </Value>
              </Item>
            </StyledItem>
          </InfoItem>
          <InfoItem>
            <Label>Total Direct Commission</Label>
            <StyledItem>
              <Item>
                <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                <Value>
                  <CountUp
                    separator=","
                    start={0}
                    preserveValue
                    delay={0}
                    end={userInfos.totalComms}
                    decimals={2}
                    duration={1}
                  />
                </Value>
              </Item>
            </StyledItem>
          </InfoItem>
          <InfoItem>
            <Label>Total Interest Commission</Label>
            <StyledItem>
              <Item>
                <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                <Value>
                  <CountUp
                    separator=","
                    start={0}
                    preserveValue
                    delay={0}
                    end={userInfos.totalComms2 * userInfos.OP2USDT}
                    decimals={2}
                    duration={1}
                  />
                </Value>
              </Item>
            </StyledItem>
          </InfoItem>
          <InfoItem>
            <Label>Total Claim Reward</Label>
            <StyledItem>
              <Item>
                <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                <Value>
                  <CountUp
                    separator=","
                    start={0}
                    preserveValue
                    delay={0}
                    end={userInfos.totalClaimUSD}
                    decimals={2}
                    duration={1}
                  />
                </Value>
              </Item>
            </StyledItem>
          </InfoItem>
          <InfoItem>
            <Label>Total Available</Label>
            <StyledItem>
              <Item>
                <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                <Value>
                  <CountUp
                    separator=","
                    start={0}
                    preserveValue
                    delay={0}
                    end={userInfos.totalAvailable}
                    decimals={2}
                    duration={1}
                  />{' '}
                </Value>
              </Item>
            </StyledItem>
          </InfoItem>
          <InfoItem>
            <Label>Total Earn (KOL + Comm + Interest Comm + Reward)</Label>
            <StyledItem>
              <Item>
                <img style={{ width: '24px', height: '24px' }} src={images.dollar} alt="icon" />
                <Value>
                  <CountUp
                    separator=","
                    start={0}
                    preserveValue
                    delay={0}
                    end={
                      reward
                    }
                    decimals={2}
                    duration={1}
                  />
                </Value>
              </Item>
            </StyledItem>
          </InfoItem>
        </Info>
        <YourCommission>
          <MyCircularProgressBar
            totalAvailable={
              userInfos.totalAvailable +
              userInfos.rewardsKolUSD / 2 +
              userInfos.totalClaimUSD +
              userInfos.totalComms * userInfos.OP2USDT +
              userInfos.totalComms2 * userInfos.OP2USDT
            }
          />
        </YourCommission>
      </BlockInfo>
    </CardInfoUser>
  )
}
export default PageProfile
